export type AlertConfiguration = {
  email: string
  id: string
  alerts?: AlertsObjectConfig
}

export type AlertsObjectConfig = {
  batterySOC: LowerConfig
  batteryVoltage: LowerConfig
  fuelLevel: LowerConfig
  connection: BooleanConfig
  error: BooleanConfig
  warning: BooleanConfig
  firmwareVersion: BooleanConfig
  fmActivePort: BooleanConfig
  multiSense?: MultisenseConfig
}

export type LowerConfig = {
  lower: { threshold: number | null; enabled: boolean }
}

export type BooleanConfig = {
  enabled: boolean
}

type MultisenseConfig = {
  a1?: MultisenseConfigType
  a2?: MultisenseConfigType
  t1?: MultisenseConfigType
  t2?: MultisenseConfigType
  io1?: MultisenseConfigType
  io2?: MultisenseConfigType
  io3?: MultisenseConfigType
  io4?: MultisenseConfigType
}

type MultisenseConfigType = {
  function: string
  unit: string
  lower: {
    threshold: null | number
    clear: null | number
    enabled: boolean
  }
  upper: {
    threshold: null | number
    clear: null | number
    enabled: boolean
  }
} | null

export const alertDefaultValues = {
  fuelLevel: { unit: '%', title: 'Total remaining fuel level below', threshold: 15 },
  batteryVoltage: { unit: 'V', title: 'Battery voltage below', threshold: 12 },
  batterySOC: { unit: '%', title: 'Battery SOC below', threshold: 15 },
  connection: { title: 'No connection' },
  error: { title: 'Error' },
  warning: { title: 'Warning' },
  firmwareVersion: { title: 'Firmware updated' },
  fmActivePort: { title: 'Fuel Manager active port changed' }
}
