import { useEffect, useState } from 'react'

// Custom hook to detect if the tab is active or inactive
function usePageVisibility () {
  const [isPageVisible, setIsPageVisible] = useState(document.visibilityState === 'visible')

  const handleVisibilityChange = () => {
    setIsPageVisible(document.visibilityState === 'visible')
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return isPageVisible
}

export default usePageVisibility
